import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './component.css';
import { IoCloseOutline } from "react-icons/io5";

function HomeSection() {

 const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    interest: '3BHK + family lounge', 
    when_can_you_plan_a_visit:'',
    status: ''
  });


  const handleOpenOverlay = () =>{
    setShowOverlay(true);
  };
  const [showOverlay, setShowOverlay] = useState(true); // State to control overlay display
  const [captchaNumbers, setCaptchaNumbers] = useState({a: 0, b: 0});

  useEffect(() => {
    // Generate random numbers for captcha
    const a = Math.floor(Math.random() * 10);
    const b = Math.floor(Math.random() * 10);
    setCaptchaNumbers({a, b});
  }, []);

  const handleChangeFormData = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://satyathehive-backend.onrender.com/trehan-form', formData);
      console.log('Form submitted successfully:', response.data);
      
      toast.success('Our Senior Executive will contact you soon', {
        position: 'top-right',
        autoClose: 3000,
        className: 'toast-container'
      });
      
      setFormData({
        name: '',
        email: '',
        mobile: '',
        when_can_you_plan_a_visit:'',
      });
     
    } catch (error) {
      toast.error("Internal Server Error");
      console.error('Error submitting form:', error);
    }
  };
  
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post('https://satyathehive-backend.onrender.com/trehan-form', formDataOverlay);
  //     console.log('Form submitted successfully:', response.data);
      
  //     toast.success('Our Senior Executive will contact you soon', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       className: 'toast-container'
  //     });

  //   } catch (error) {
  //     toast.error('Internal Server Error', {
  //       position: 'top-right',
  //       autoClose: 3000,
  //       className: 'toast-container',
  //       zIndex: 1000
  //     });
  //     console.error('Error submitting form:', error);
  //   }
  // };
  
  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };
 

  const bannerformValidation = (e) => {
    e.preventDefault();
    const captchaInput = parseInt(document.getElementById('captcha').value);
    const { a, b } = captchaNumbers;
    if (captchaInput === (a + b)) {
      handleSubmit(e);
      console.log("Captcha is Correct!");
    } else {
      toast.error("Please solve the captcha correctly");
    }
  };

  return (
    <div className='hero'>
      <ToastContainer position="top-right" autoClose={3000} closeButton={true} className="toast-container" />
      <div className='mobile-details'>
        <div className="hero-mobile-container">
          <div className="row">
            <div className="header-hero-mobile">
              <h1 className="tlr-heading">
                Trehan Luxury Floors
                <br />
                <span>Ready to Move In Luxury Floors</span>
              </h1>
              <h5>Anant Raj Sector 63A, Gurgaon</h5>
              <h5>3 &amp; 4 BHK Luxury Low Rise Floors</h5>
              <h5>Price Starts ₹ 2 Cr* onwards</h5>
              <div className="banner-btns">
                <a href="tel:9289252999" id="filled" className="btn">Call Now</a>
                <button className='btn' onClick={handleOpenOverlay} >Download Brochure</button>
              </div>
            </div>
            <div className="hero-form-main">
              <div className="banner-form text-center">
                <div className="enquiry-form" id="come">
                  <h4 className='new-invest'>New Investment Opportunities Not To Miss!</h4>
                  <form className='hero-form-content' name="bannerform" method="post" action="sendemail.php" onSubmit={bannerformValidation}>
                    <input type="hidden" name="subject" value="Get In Touch With Us-Bannner Form" />
                    <div className="form-group">
                      <input type="text" name="name" placeholder="Enter Your Name" className="form-control" value={formData.name} onChange={handleChangeFormData} required/>
                    </div>
                    <div className="form-group">
                      <input type="phone" name="mobile" placeholder="Enter Your Phone" className="form-control" value={formData.mobile} onChange={handleChangeFormData} required/>
                    </div>
                    <div className="form-group">
                      <input type="email" name="email" placeholder="Enter Your Email" className="form-control" value={formData.email} onChange={handleChangeFormData} required/>
                    </div>
                    <div className='form-group'>
                      <input placeholder='Date for visit' type='text' id="visit" name='when_can_you_plan_a_visit' value={formData.when_can_you_plan_a_visit} onChange={handleChangeFormData} required />
                    </div>
                    <div className="form-group1">
                      <p className="plus-text">
                        {captchaNumbers.a} + {captchaNumbers.b} =
                      </p>
                      <input type="text" className="captcha-input" name="captcha" id="captcha" placeholder="Please enter Total" required />
                    </div>
                    <div className="hero-form-submit">
                      <button type="submit" >Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className='form-container-hero'>
        <div className='form-div-hero'>
          <div className='headers-hero'>
            <h2>Let's Get Connected!</h2>
          </div>
          <form onSubmit={handleSubmit}>
            <div>
              <label htmlFor="name">Name:</label>
              <input placeholder='Enter Your Name' type="text" id="name" name="name" value={formData.name} onChange={handleChangeFormData } required />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input placeholder='Enter Your Email' type="email" id="email" name="email" value={formData.email} onChange={handleChangeFormData  } required />
            </div>
            <div>
              <label htmlFor="mobile">Mobile:</label>
              <input placeholder='Enter Your Mobile Number' type="tel" id="mobile" name="mobile" value={formData.mobile} onChange={handleChangeFormData } required />
            </div>
            <div>
              <label htmlFor="when_can_you_plan_a_visit">When Can You Plan A Visit?</label>
              <input placeholder='Date for visit' type='text' id="visit" name='when_can_you_plan_a_visit' value={formData.when_can_you_plan_a_visit} onChange={handleChangeFormData } required />
            </div>
            <button type="submit" className='form-submit-hero'>Submit</button>
          </form>
        </div>
      </div>

      {showOverlay && (
        <div className='overlay'>
          <div className='form-container'>
            <div className='form-div'>
              <div className="close-icon" onClick={handleCloseOverlay}>
                <IoCloseOutline />
              </div>
              <div className='headers'>
                <h2>Let's Get Connected!</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div>
                  <label htmlFor="name">Name:</label>
                  <input placeholder='Enter Your Name' type="text" id="name" name="name" value={formData.name} onChange={handleChangeFormData} required />
                </div>
                <div>
                  <label htmlFor="email">Email:</label>
                  <input placeholder='Enter Your Email' type="email" id="email" name="email" value={formData.email} onChange={handleChangeFormData} required />
                </div>
                <div>
                  <label htmlFor="mobile">Mobile:</label>
                  <input placeholder='Enter Your Mobile Number' type="tel" id="mobile" name="mobile" value={formData.mobile} onChange={handleChangeFormData} required />
                </div>
                <div>
                  <label htmlFor="when_can_you_plan_a_visit">When Can You Plan A Visit?</label>
                  <input placeholder='Date for visit' type='text' id="visit" name='when_can_you_plan_a_visit' value={formData.when_can_you_plan_a_visit} onChange={handleChangeFormData} required />
                </div>
                <button type="submit" className='form-submit'>Submit</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeSection;
