// Overlay.js
import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IoCloseOutline } from "react-icons/io5";

const Overlay = ({ onClose }) => {
  const [formDataOverlay, setFormDataOverlay] = useState({
    name: '',
    email: '',
    mobile: '',
    when_can_you_plan_a_visit: '',
  });

  const handleChangeOverlay = (e) => {
    const { name, value } = e.target;
    setFormDataOverlay({ ...formDataOverlay, [name]: value });
  };

  const handleSubmitOverlay = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://satyathehive-backend.onrender.com/trehan-form', formDataOverlay);
      console.log('Form submitted successfully:', response.data);

      toast.success('Our Senior Executive will contact you soon', {
        position: 'top-right',
        autoClose: 3000,
        className: 'toast-container'
      });

      setFormDataOverlay({
        name: '',
        email: '',
        mobile: '',
        when_can_you_plan_a_visit: '',
      });

      onClose(); // Close the overlay after form submission
    } catch (error) {
      toast.error('Internal Server Error', {
        position: 'top-right',
        autoClose: 3000,
        className: 'toast-container',
        zIndex: 1000
      });
      console.error('Error submitting form:', error);
    }
  };

  const handleCloseOverlay = () => {
    onClose(); // Close the overlay when close icon is clicked
  };

  return (
    <div className='overlay-all'>
      <div className='form-container'>
        <div className='form-div'>
          <div className="close-icon" onClick={handleCloseOverlay}>
            <IoCloseOutline />
          </div>
          <div className='headers'>
            <h2>Let's Get Connected!</h2>
          </div>
          <form onSubmit={handleSubmitOverlay}>
            <div>
              <label htmlFor="name">Name:</label>
              <input placeholder='Enter Your Name' type="text" id="name" name="name" value={formDataOverlay.name} onChange={handleChangeOverlay} required />
            </div>
            <div>
              <label htmlFor="email">Email:</label>
              <input placeholder='Enter Your Email' type="email" id="email" name="email" value={formDataOverlay.email} onChange={handleChangeOverlay} required />
            </div>
            <div>
              <label htmlFor="mobile">Mobile:</label>
              <input placeholder='Enter Your Mobile Number' type="tel" id="mobile" name="mobile" value={formDataOverlay.mobile} onChange={handleChangeOverlay} required />
            </div>
            <div>
              <label htmlFor="when_can_you_plan_a_visit">When Can You Plan A Visit?</label>
              <input placeholder='Date for visit' type='text' id="visit" name='when_can_you_plan_a_visit' value={formDataOverlay.when_can_you_plan_a_visit} onChange={handleChangeOverlay} required />
            </div>
            <button type="submit" className='form-submit'>Submit</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Overlay;
