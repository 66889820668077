import React from 'react';
import "./component.css";
import image1 from "../All Images/amenities/amenities-1.webp";
import image2 from "../All Images/amenities/amenities-2.webp";
import image3 from "../All Images/amenities/amenities-3.webp";
import image4 from "../All Images/amenities/amenities-4.webp";
import image5 from "../All Images/amenities/amenities-5.webp";
import image6 from "../All Images/amenities/amenities-6.webp";
import image7 from "../All Images/amenities/amenities-7.webp";
import image8 from "../All Images/amenities/amenities-8.webp";

function Amenities() {
  return (
    <div className='amenities-main'>
      <h1> Project Amenities <span>That we provide</span></h1>
      
      <div className='amenities-div'>
        <div className='amenities-img'>
          <img src={image1} alt="amenities" />
           <div className="text-overlay">Club House</div>
        </div>
        <div className='amenities-img'>
          <img src={image2} alt="amenities" />
           <div className="text-overlay">Jogging Track</div>
        </div>
        <div className='amenities-img'>
          <img src={image3} alt="amenities" />
           <div className="text-overlay">Kids Play Area</div>
        </div>
        <div className='amenities-img'>
          <img src={image4} alt="amenities" />
           <div className="text-overlay">Swimming Pool</div>
        </div>
        <div className='amenities-img'>
          <img src={image5} alt="amenities" />
           <div className="text-overlay">Fitness Corner</div>
        </div>
        <div className='amenities-img'>
          <img src={image6} alt="amenities" />
           <div className="text-overlay">Meditation Zone</div>
        </div>
        <div className='amenities-img'>
          <img src={image7} alt="amenities" />
           <div className="text-overlay">Rock Climbing</div>
        </div>
        <div className='amenities-img'>
          <img src={image8} alt="amenities" />
           <div className="text-overlay">Modular Kitchen</div>
        </div>

      </div>
      </div>
  )
}

export default Amenities