import React, { useState } from 'react';
import { FaBuilding } from 'react-icons/fa';
import { PiCursorClickBold } from "react-icons/pi";
import image1 from '../All Images/3BHK sector 71/(Floor map ) TREHAN 3BHK LUXURY FLOORS SECTOR-71.png';
import image2 from '../All Images/3BHK sector 71/(Floor plan)TREHAN 3BHK LUXURY FLOORS SECTOR-71 _page-0002.jpg';
import spec from '../All Images/3BHK sector 71/(specs)trehan-floors-ansal-esencia-sector-67.webp';
import Overlay from "./Overlay";

const specsList = [
  'Central Air Conditioners',
  'Fast and Secured Elevators',
  'Moreover, Ceiling Fans',
  'High Quality modern wardrobes and kitchens',
  'Wooden Flooring in all the bedrooms',
  'Reserved Parking For Each Unit',
  'Afterwards, Vitrified Tiles',
  'Furthermore, Video Door Security',
  'Interior - Plastic Emulsion Paint',
  'Plastic Emulsion Paint',
  '24 Hours Power Backup',
  'In addition, Opulent balconies.'
];

const ProjectSpecs = () => {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleButtonClick = () => {
    setShowOverlay(true);
    console.log('Overlay Opened');
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  return (
    <div className='project-specs'>
      <h1>Project Specifications</h1>
      <div className='specs'>
        <div className='specs-2'>
          <img src={spec} alt='Project Specs' />
          <ul>
            {specsList.map((spec, index) => (
              <li key={index}><FaBuilding /> {spec}</li>
            ))}
          </ul>
        </div>
      </div>
      <h1>Floor Plan</h1>
      <div className='floor-plan-container'>
        <div className='left-plan'>
          <img src={image1} alt='Floor Plan' />
          <button className='center-button' onClick={handleButtonClick}>Click Here For More Details <PiCursorClickBold className='cursor-click'/></button>
        </div>
        <div className='right-plan'>
          <img src={image2} alt='Floor Plan' />
          <button className='center-button' onClick={handleButtonClick}>Click Here For More Details <PiCursorClickBold className='cursor-click'/> </button>
        </div>
      </div>
      {showOverlay && <Overlay onClose={handleCloseOverlay} />}
    </div>
  );
}

export default ProjectSpecs;
