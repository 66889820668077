import React from 'react';

function Footer() {
  

    return (
        <div className='footer'>
            <h4>Featured By</h4>
            <h3>
                <a href="https://www.realtyshopee.in" target="_blank" rel="noopener noreferrer">REALTY SHOPEE</a>
            </h3>
            
        </div>
    );
}

export default Footer;
