import React from 'react';
import Navbar from './components/Navbar.jsx';
import HomeSection from './components/HomeSection';
import './App.css';
import Amenities from './components/Amenities.jsx';
// import Location from './components/Location.jsx';
import ContactUs from './components/ContactUs.jsx';
import Footer from './components/Footer.jsx';
import LocationSpecs from './components/LocationSpecs.jsx';
import ProjectSpecs from './components/ProjectSpecs.jsx';
import Pricing from './components/Pricing.jsx';

const App = () => {
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    const navbarHeight = document.querySelector('.navbar').offsetHeight;
  
    if (section) {
      const sectionTop = section.offsetTop - navbarHeight;
      window.scrollTo({
        top: sectionTop,
        behavior: 'smooth',
      });
    }
  };
  
  

  return (
    <div className="App">
      <Navbar scrollToSection={scrollToSection} />
      <div id="home">
        <HomeSection />
      </div>
      <div id="projectSpecs">
        <ProjectSpecs/>
      </div>
      <div id="amenities">
        <Amenities />
      </div>
    
      <div id="locationSpecs">
        <LocationSpecs />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
     <div id="contactUs" >
      <ContactUs />
     </div>
     <Footer/>
    </div>
  );
}

export default App;
