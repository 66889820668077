import React, { useState } from 'react';
import Overlay from './Overlay'; // Import the Overlay component
import './component.css';

function Pricing() {
  const [showOverlay, setShowOverlay] = useState(false); // State to control overlay display

  const handleEnquireNow = () => {
    setShowOverlay(true); // Show the overlay when "Enquire Now" button is clicked
  };

  const handleCloseOverlay = () => {
    setShowOverlay(false); // Close the overlay when requested
  };

  return (
    <div className='pricing-main'>
      <h1>Pricing</h1>
      <div className='pricing-div'>
        <div className='pricing-card'>
          <h2>3 BHK</h2>
          <h3>Starting From ₹ 2.18 Cr</h3>
          <p>Area: 1611-2000 sqft</p>
        </div>
        <div className='pricing-para'>
          <p>
            To get the pricing details and more details about the property schedule a visit with us.
          </p>
          <p>
            <span>Call us at:</span> <strong><a href='tel:+919289252999'>+91 9289252999</a></strong>
          </p>
          <button onClick={handleEnquireNow}>Enquire Now</button>
        </div>
      </div>
      {showOverlay && <Overlay onClose={handleCloseOverlay} />} {/* Render the overlay when showOverlay is true */}
    </div>
  );
}

export default Pricing;
