import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import "./component.css";
import contactlogo from "../All Images/navlogo.png";
import {MdMailOutline} from "react-icons/md"
import {RiWhatsappFill} from "react-icons/ri"

function ContactUs() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
    interest: '3BHK + family lounge',
    when_can_you_plan_a_visit:'',
    status: ''
  });
  const [formVisible, setFormVisible] = useState(true); // State to control form visibility

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFormVisible(true);
    }, 5000);

    return () => clearTimeout(timeout);
  }, [formVisible]); // Listen for changes in formVisible state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://satyathehive-backend.onrender.com/trehan-form', formData);
      console.log('Form submitted successfully:', response.data);
      toast.success('Our Senior Executive will contact you soon', {
        position: 'top-right',
        autoClose: 3000,
        className: 'toast-container'
      });

      // Clear form data after successful submission
      setFormData({
        name: '',
        email: '',
        mobile: '',
        interest: '3BHK + family lounge',
        when_can_you_plan_a_visit:'',
        status: ''
      });
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
 
  return (
    <div className='contact-main'>
      <div className='contact-logo'>
        <img src={contactlogo} alt="Trehan logo"/>
      </div>
      <div className='contact-address'>
        <h1>Address</h1>
          <p> Emaar The Palm Square, C-1/12-005, Badshahpur,<br/> Sector 66, Gurugram, Haryana 122012</p>
      </div>
      <div className='contact-us'>
        <h1>Contact Us</h1>
        <p><a href="mailto:sachin@realtyshopee.com"><MdMailOutline className='mail-icon'/> sachin@realtyshopee.com</a></p>
        <p><a href="https://wa.me/+919289252999"><RiWhatsappFill className='whatsapp-icon'/> +91 9289252999</a></p>
      </div>
    
      <div className='form-div1' >
        <div className='headers1'>
          <h4>Let's Get Connected!</h4>
          <h2>Send Us a Message</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="div1">
            <label htmlFor="name">Name:</label>
            <input placeholder='Name' type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="div1">
            <label htmlFor="email">Email:</label>
            <input placeholder='Email' type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="div1">
            <label htmlFor="mobile">Mobile:</label>
            <input placeholder='Enter Your Mobile Number' type="tel" id="mobile" name="mobile" value={formData.mobile} onChange={handleChange} required />
          </div>
          <div className='divs2' style={{ flexDirection: "column" }}>
            <label htmlFor="when_can_you_plan_a_visit" style={{ fontSize: "14px" }}>When Can You Plan A Visit?</label>
            <input placeholder='Date for visit' type='date' id="when_can_you_plan_a_visit" name='when_can_you_plan_a_visit' value={formData.when_can_you_plan_a_visit} onChange={handleChange} required />
          </div>
          
          {/* <div>
            <label htmlFor="interest">Interested In:</label>
            <select id="interest" name="interest" value={formData.interest} onChange={handleChange}>
              <option value="3BHK + family lounge">3BHK + family lounge</option>
            </select>
          </div> */}
          <button type="submit" className='form-submit'>Submit</button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
