import React, { useState } from 'react';
import { MdMenuOpen } from "react-icons/md";
import './component.css'; // Import CSS file for styling
import navlogo from "../All Images/navlogo.png";
import calling from "../All Images/Animation svg/callingLogo.png"
import { FaHeadset } from "react-icons/fa";


function Navbar({ scrollToSection }) {
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleButtonClick = (sectionId) => {
    if (sectionId === 'overview') {     
       window.scrollTo({ top: 0, behavior: 'smooth' }); 
    } else {
      scrollToSection(sectionId); 
    }
    toggleDropdown(); 
  };

  const handleCall=()=>{
    console.log('Calling');
    const phoneNumber = '+919289252999';
    const telUri = `tel:${phoneNumber}`;
    window.open(telUri, '_self');

  };

  return (
    <nav className="navbar">
      <div className="navbar-left">
        <div className="logo">
          
            <img src={navlogo} alt="trehan logo"/>
         </div>
      </div>
      <div className='navbar-center'>
        <button className="navbar-buttons" onClick={() => handleButtonClick('home')}>Overview</button>
        <button className="navbar-buttons" onClick={() => handleButtonClick('projectSpecs')}>Specifications</button>
        <button className="navbar-buttons" onClick={() => handleButtonClick('amenities')}>Amenities</button>
        <button className="navbar-buttons" onClick={() => handleButtonClick('locationSpecs')}>Nearby</button>
        <button className="navbar-buttons" onClick={() => handleButtonClick('pricing')}>Pricing</button>
        <button className="navbar-buttons" onClick={() => handleButtonClick('contactUs')}>Contact Us</button>
      </div>
      <div className="navbar-right">
        <button className="call-button" onClick={handleCall} ><img src={calling} alt="call"/>+91 9289252999</button>
          <FaHeadset className='headset' onClick={handleCall}/>
      </div>
      <div className='dropdown' onClick={toggleDropdown}>
     
      
        <MdMenuOpen className='menu'/>
        {showDropdown && (
          <div className="dropdown-content">
            <button className="dropdown-item" onClick={() => handleButtonClick('home')}>Overview</button>
            {/* <button className="dropdown-item" onClick={() => handleButtonClick('projects')}>Projects</button> */}
            <button className="dropdown-item" onClick={() => handleButtonClick('project-specs')}>Specifications</button>
            {/* <button className="dropdown-item" onClick={() => handleButtonClick('floorPlan')}>Floor Plan</button> */}
            <button className="dropdown-item" onClick={() => handleButtonClick('amenities')}>Amenities</button>
            <button className="dropdown-item" onClick={() => handleButtonClick('locationSpecs')}>Nearby</button>
            <button className="dropdown-item" onClick={() => handleButtonClick('pricing')}>Pricing</button>
            <button className="dropdown-item" onClick={() => handleButtonClick('contactUs')}>Contact Us</button>

          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;