import React from 'react';
import './component.css';
import location from "../All Images/3BHK sector 71/(Location) TREHAN 3BHK LUXURY FLOORS SECTOR-71.png"

const locationDetails = [
  {
    title: 'Schools',
    detail1: 'GD Goenka School',
    detail2: 'The Paras World School',
    detail3: 'St.Xaviers High School',
    detail4: 'DPS INTERNATIONAL'
  },
  {
    title: 'Metro Station',
    detail1: 'Sector 54 Rapid Metro',
    detail2: 'Huda City Center'
  },
  {
    title: 'Hospitals',
    detail1: 'Medanta-The Medicity',
    detail2: 'Ck Birla',
    detail3: 'Park Hospital',
    detail4: 'Apollo Hospital(Under Way)'
  },
  {
    title: 'Theatre',
    detail1: 'SRS Cinemas',
    detail2: 'Grand Cinemas',
    detail3: 'Carnival Cinemas',
    detail4: 'Elan PVR (underway)'
  },
  {
    title: 'Shopping',
    detail1: 'Omaxe City Centre',
    detail2: 'Good Earth City Centre',
    detail3: 'Element One',
    detail4: 'Airia Mall'
  },
  {
    title: 'Restaurants',
    detail1: '32 Avenue',
    detail2: 'HYATT REGENCY',
    detail3: 'REFLEX BAR',
    detail4: 'M3M BROADWAY',
  },
];

function LocationSpecs() {
  const topCards = locationDetails.slice(0, 3);
  const bottomCards = locationDetails.slice(3);

  return (
    <div className='location-specs'>
      <h1>Nearby Locations</h1>
      <div className='nearby'>
        <div className='location-image'>
          <img src={location} alt="" />
        </div>
        <div className='nearby-cards'>
          {/* Render the top three cards */}
          <div className='top-cards'>
            {topCards.map((location, index) => (
              <div key={index} className='card'>
                <h2>{location.title}</h2>
                <p>1. {location.detail1}</p>
                <p>2. {location.detail2}</p>
                {location.detail3 && <p>3. {location.detail3}</p>}
                {location.detail4 && <p>4. {location.detail4}</p>}
              </div>
            ))}
          </div>
          {/* Render the bottom three cards */}
          <div className='bottom-cards'>
            {bottomCards.map((location, index) => (
              <div key={index} className='card'>
                <h2>{location.title}</h2>
                <p>1. {location.detail1}</p>
                <p>2. {location.detail2}</p>
                <p>3. {location.detail3}</p>
                <p>4. {location.detail4}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LocationSpecs;
